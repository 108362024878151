<template>
  <DialogBase
    visible
    ref="dialog"
    :title="Title"
    icon="mdi-information"
    maxWidth
    set
    complete
    close
    @clickSet="set()"
    @clickComplete="complete()"
    @clickClose="close()"
  >
    <v-form v-model="valid" ref="form">
      <!-- {{ edit }} -->
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="12">
          <v-autocomplete
            v-model="selectUser"
            :items="users"
            multiple
            outlined
            clearable
            :rules="[Rules.Required]"
            label="社員"
            item-text="name"
            item-value="code"
            chips
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            v-model="selectDays"
            :items="days"
            outlined
            multiple
            clearable
            :rules="[Rules.Required]"
            label="日付"
            item-text="name"
            item-value="code"
            chips
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            v-model="selectPattern"
            :items="patterns"
            outlined
            label="シフトパターン"
            item-text="name"
            item-value="code"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-actions>
              <v-btn text>
                設定内容
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn icon @click="show = !show">
                <v-icon>{{
                  show ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-show="show">
                <v-divider></v-divider>
                <vue-good-table
                  v-if="setShift && setShift.length"
                  :columns="cols"
                  :rows="setShift"
                  :sort-options="{
                    enabled: false
                  }"
                  :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    perPage: 10,
                    perPageDropdown: [5, 10],
                    dropdownAllowAll: true,
                    setCurrentPage: 1,
                    nextLabel: 'next',
                    prevLabel: 'prev',
                    rowsPerPageLabel: '1ページ表示件数',
                    ofLabel: 'of',
                    pageLabel: 'page',
                    allLabel: 'All'
                  }"
                  :search-options="{
                    enabled: true,
                    placeholder: '簡易検索'
                  }"
                >
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'actions'">
                      <v-icon
                        small
                        @click="deleteRow(props.row)"
                        class="ml-1"
                        color="error"
                      >
                        mdi-delete
                      </v-icon>
                    </span>
                  </template>
                </vue-good-table>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </DialogBase>
</template>

<script>
import Common from "@/mixins/common";
import Dialog from "@/mixins/dialog";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import { uuid } from "vue-uuid";

const Headers = [
  {
    value: "date",
    text: "日付"
  },
  {
    value: "name",
    text: "社員"
  },
  {
    value: "shiftname",
    text: "シフト"
  },
  {
    value: "abbreviate",
    text: "略称"
  },
  {
    value: "actions",
    text: "操作"
  }
];
export default {
  name: "ShiftUserAddDialog",
  mixins: [Common, Dialog, Forms, Api, ShowDialogs],
  components: {
    VueGoodTable
  },
  computed: {
    Title() {
      const args = this.args;
      return args.affiliationname + " 社員追加";
    }
  },
  data() {
    return {
      selectUser: [],
      users: [],
      selectDays: [],
      days: [],
      selectPattern: { code: 0, name: "" },
      patterns: [],
      targetIndex: 0,
      setShift: [],
      show: false,
      cols: [],
      rows: []
    };
  },
  methods: {
    close() {
      this.resolve([]);
      this.$close();
    },
    async deleteRow(row) {
      console.log("deleteRow", row);
      if (await this.deleteConfirm()) {
        this.setShift = this.setShift.filter(e => e.uuid !== row.uuid);
      }
    },
    async deleteConfirm() {
      return await this.$deleteConfirm("削除します。\nよろしいですか？");
    },
    async completeConfirm() {
      return await this.$confirm("設定します。\nよろしいですか？");
    },
    set() {
      console.log("set");
      if (!this.$refs.form.validate()) return;

      const pattern = this.selectPattern.code == 0 ? 0 : this.selectPattern;

      const inputPatternIndex = [];
      inputPatternIndex.push(pattern);

      this.selectUser.forEach(user => {
        const selectptn = this.patterns.filter(e => e.code === pattern);

        const targetUser = this.users.filter(e => e.code === user);
        this.selectDays.forEach(day => {
          const setval = {
            user: user,
            name: targetUser[0].name,
            date: day,
            pattern: inputPatternIndex,
            shiftname: selectptn[0].name,
            abbreviate: selectptn[0].abbreviate,
            uuid: uuid.v4()
          };
          this.setShift.push(setval);
        });
      });

      this.setShift.sort((a, b) => {
        if (a.date < b.date) return -1;
        if (a.date > b.date) return 1;
        if (a.user < b.user) return -1;
        if (a.user > b.user) return 1;
      });

      this.selectUser = [];
      this.selectDays = [];

      this.show = true;
    },
    async complete() {
      console.log("complate");
      if (await this.completeConfirm()) {
        this.$close(this.setShift);
      }
    }
  },
  created() {
    console.log("created", this.args);
    this.targetIndex = this.args.targetIndex;
    this.users = this.args.users;
    this.days = this.args.days;
    this.patterns = this.args.pattern;
    this.setShift = [];

    this.cols = Headers.map(header => ({
      label: header.text,
      field: header.value
    }));
  }
};
</script>
<style>
.vgt-wrap__footer .footer__row-count__label,
.vgt-wrap__footer .footer__navigation,
.vgt-wrap__footer .footer__row-count__select,
.vgt-wrap__footer .footer__navigation__page-btn span {
  font-size: 14px;
}
</style>
